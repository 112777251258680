import React from 'react';
import '../stylesheets/Info.scss';
import kenta from '../images/kenta.png';
import puku from '../images/puku.png';
import line from '../images/line.jpg';
import { useInView } from 'react-intersection-observer';

function Info() {
  const [ref, inView] = useInView({
    rootMargin: '-10px',
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div id="info" className="Info section">
      <div className="Info__title">
        <p>INFO</p>
      </div>
      <div className="Info__desc">
        member
      </div>
      <div className="Info__members-section"
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(50px)",
        transition: "all 2s ease"
      }}
      >
        <div className="Info__members-section__member">
          <table>
            <tbody>
              <tr>
                <td className="Info__members-section__picture">
                  <img src={ kenta } alt="kentaの写真"/>
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__job">
                  Director & Marketing
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__name">
                  Kentaro
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__desc">
                  <p>福岡大学の体育学部を卒業後、介護関係の仕事に1年間従事</p>
                  <p>その後、持ち前のコミュニケーションスキルを生かし、スポーツトレーナーへ転職</p>
                  <p>トレーナーとしてお客の悩みに寄り添い、1000人以上をマネジメント</p>
                  <p>現在は、スポーツクラブルネサンスでトレーナー業と両立しながらSonicCreateにて活動中</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Info__members-section__member"
        style={{
          opacity: inView ? 1 : 0,
          transform: inView ? "translateY(0)" : "translateY(50px)",
          transition: "all 2s ease"
        }}
        >
          <table>
            <tbody>
              <tr>
                <td className="Info__members-section__picture">
                  <img src={ puku } alt="pukuの写真"/>
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__job">
                  Engineer
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__name">
                  Eggman
                </td>
              </tr>
              <tr>
                <td className="Info__members-section__desc">
                  <p>鹿児島高専卒業後、5年間カメラ製造メーカーにて勤務</p>
                  <p>画像処理とExelVBAによる業務改善で作業を大幅に短縮の実績</p>
                  <p>2020年からWeb業界へ転職</p>
                  <p>サーバーサイドからフロントエンドまでフルスタックで活動中</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="Info__contact-section"
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(50px)",
        transition: "all 2s ease"
      }}
      >
        <div className="Info__desc">
          contact<br />
          <span
            style={{
              opacity: inView ? 1 : 0,
              background: '-webkit-linear-gradient(left, rgb(255, 250, 153) 50%, transparent 50%)',
              backgroundRepeat: 'no-repeat',
              transition: "5s",
              backgroundPosition: inView ? '0% 1em' : '100% 1em',
              backgroundSize: '200% 80%',
              animation: 'SlideIn 3s ease-out both'
            }}
          >お問い合わせ、ご質問はこちらにご連絡お願いいたします</span>
        </div>
        <div className="Info__contact-form">
          <form className="Info__contact-form__form" method="POST" name="contact" >
            <input type="hidden" name="form-name" value="contact" />
            <div className="Info__contact-form__section">
              <div>お名前<span> ※必須</span></div>
              <input type="text" name="name" id="name" required className="Info__contact-form__input"/>
            </div>
            <div className="Info__contact-form__section">
              <div>メールアドレス<span> ※必須</span></div>
              <input type="email" name="email" id="email" required className="Info__contact-form__input" />
            </div>
            <div className="Info__contact-form__section">
              <div>電話番号<span> ※必須</span></div>
              <input type="tel" name="tel" id="tel" required className="Info__contact-form__input"/>
            </div>
            <div className="Info__contact-form__section">
              <div>質問・相談内容<span> ※必須</span></div>
              <textarea rows="10" name="question" id="question" required className="Info__contact-form__input"/>
            </div>

            <div class="recaptcha-item">
              <div data-netlify-recaptcha="true"></div>
            </div>

            <div className="Info__contact-form__section__submit">
              <input type="submit" value="送信する" className="Info__contact-form__submit"/>
            </div>
          </form>
        </div>
        <div className="Info__contact">
          <table>
            <tbody>
              <tr>
                <td className="Info__contact__tel">
                  TEL
                  <div>080-7986-2907</div>
                </td>
              </tr>
              <tr>
                <td className="Info__contact__mail">
                  MAIL
                  <div>soniccreate2021@gmail.com</div>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="Info__contact__line">
                  LINE
                  <div className="Info__contact__line-img">
                    <img src={line} alt="lineのQR" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Info;
