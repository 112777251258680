import React from 'react';
import '../stylesheets/Achievements.scss';
import yamaguchidcc from '../images/yamaguchi-dcc.png';
import oocafe from '../images/oocafe.png';
import bararrow from '../images/bararrow.png';
import youstyle from '../images/youstyle.png';
import { useInView } from 'react-intersection-observer';

function Achievements() {
  const [ref, inView] = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
    threshold: 0,
  });

  let list = [];
  let data = [
    {
      img: yamaguchidcc,
      name: "山口ダンスカンパニー",
      desc: "ホームページの作成、ヘッドレスCMSによるコンテンツの投稿機能を実装いたしました。自身で投稿を管理できるため、お悩みであった写真の委託投稿費用を解決しました",
      link: "https://yamaguchi-dcc.com/"
    },
    {
      img: oocafe,
      name: "まるまるカフェ",
      desc: "ホームページの作成、ヘッドレスCMSによるコンテンツの投稿機能を実装いたしました",
      link: "https://oocafe.net/"
    },
    {
      img: bararrow,
      name: "AmusementBarArrow",
      desc: "ホームページの作成、ヘッドレスCMSによるカレンダー連携イベント投稿システムを実装で、イベントの管理をできるようにしました。",
      link: "https://amusementbar-arrow.com/"
    },
    {
      img: youstyle,
      name: "YouStyle",
      desc: "ホームページの作成、予約外部サービスの埋め込みを実装して、レッスンの予約も可能なサイトに仕上げました。",
      link: "https://www.studio-youstyle.com/"
    },
  ];

  for (let i in data) {
    list.push(
      <div key={i} className="Achievements__content-section__content">
        <table>
          <tbody>
            <tr>
              <td className="Achievements__content-section__picture">
                <a href={ data[i].link }>
                  <img className="Achievements__content-section__picture__img" src={data[i].img} alt={data[i].name} />
                </a>
              </td>
            </tr>
            <tr>
              <td className="Achievements__content-section__name">
                {data[i].name}様
              </td>
            </tr>
            <tr>
              <td className="Achievements__content-section__desc">
                {data[i].desc}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="achievements" className="Achievements section">
      <div className="Achievements__title">
        <p>ACHIEVEMENTS</p>
      </div>
      <div className="Achievements__desc">
        実績の紹介
      </div>
      <div className="Achievements__contents-section"
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(50px)",
        transition: "all 2s ease"
      }}
      >
        <div className="Achievements__contents-section__wrapper">
          {list}
        </div>
      </div>
    </div>
  );
}

export default Achievements;
