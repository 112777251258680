import React from 'react';
import '../stylesheets/Top.scss';
import TopImage from '../images/sonic_create_text.png'

function Top() {
  return (
    <div id="top" className="Top">
      <div className="Top-section">
        <img className="Top-section__image" src={TopImage} alt="TOPの画像です" />
        <div className="Top-section__circle1" />
        <div className="Top-section__circle2" />
        <div className="Top-section__circle3" />
      </div>
    </div>
  );
}

export default Top;
