import React from 'react';
import '../stylesheets/Tech.scss';
import tech from '../images/tech.png';
import html from '../images/html.png';
import css from '../images/css.png';
import vue from '../images/vue.png';
import react from '../images/react.png';
import rails from '../images/rails.png';
import go from '../images/go.png';
import { useInView } from 'react-intersection-observer';

function Tech() {
  const [ref, inView] = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <div id="tech" className="Tech">
      <div className="Tech__title">
        <p>TECH SKILLS</p>
      </div>
        <div
          ref={ref}
          style={{
            opacity: inView ? 1 : 0,
            transform: inView ? "translateY(0)" : "translateY(50px)",
            transition: "all 2s ease"
          }}
        >
        <div className="Tech__desc">
          <p><span
              style={{
                opacity: inView ? 1 : 0,
                background: '-webkit-linear-gradient(left, rgb(255, 250, 153) 50%, transparent 50%)',
                backgroundRepeat: 'no-repeat',
                transition: "5s",
                backgroundPosition: inView ? '0% 1em' : '100% 1em',
                backgroundSize: '200% 80%',
                animation: 'SlideIn 3s ease-out both'
              }}
            >
            SonicCreateでは多種多様なスキルで、その提案を実現致します</span></p>
        </div>
        <div className="Tech__logo-section">
          <img className="Tech__logo-section__tech-img" src={tech} alt="techのイメージ画像" />
          <div className="Tech__logo-section-stack">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ html } alt="htmlのロゴ"/>
                    </div>
                  </td>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ css } alt="cssのロゴ"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ vue } alt="vueのロゴ"/>
                    </div>
                  </td>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ react } alt="reactのロゴ"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ rails } alt="railsのロゴ"/>
                    </div>
                  </td>
                  <td>
                    <div className="Tech__logo-section-stack__img">
                      <img src={ go } alt="goのロゴ"/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tech;
