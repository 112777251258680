import React from 'react';
import Sidenav from './components/Sidenav';
import Top from './components/Top';
import Message from './components/Message';
import Tech from './components/Tech';
import Achievements from './components/Achievements';
import Info from './components/Info';
import Footer from './components/Footer';
import './stylesheets/App.scss';

function App() {
  return (
    <div className="App">
      <div className="App__navSection">
        <Sidenav />
      </div>
      <div className="App__contetentSection">
        <Top />
        <Message />
        <Tech />
        <Achievements />
        <Info />
        <Footer />
      </div>
    </div>

  );
}

export default App;
