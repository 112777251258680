import '../stylesheets/Message.scss';
import { useInView } from 'react-intersection-observer';
import React from 'react';

function Message() {
  const [ref, inView] = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div id="message" className="Message">
      <div className="Message__title">
        <p>MESSAGE</p>
      </div>
      <div className="Message__desc"
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(50px)",
        transition: "all 2s ease",
      }}
      >
        <p className='Message__desc-main'>
          <span
            style={{
                opacity: inView ? 1 : 0,
                background: '-webkit-linear-gradient(left, rgb(255, 250, 153) 50%, transparent 50%)',
                backgroundRepeat: 'no-repeat',
                transition: "5s",
                backgroundPosition: inView ? '0% 1em' : '100% 1em',
                backgroundSize: '200% 80%',
            }}>ムダのない世界でもっと自由に
          </span></p>
        <p>サイトの作成、業務改善システムの導入など、お気軽にご相談ください。</p>
        <p>お客様一人ひとりに合った提案で要望を実現いたします。</p>
      </div>
    </div>
  );
}

export default Message;
