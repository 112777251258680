import React from 'react';
import '../stylesheets/Sidenav.scss';

function Sidenav() {

  const handleClick = () => {
    let checkbox
    checkbox = document.getElementById("menu-btn-check");
    checkbox.checked = false
  }  

  return (
    <div className="Sidenav">
      <div className="Sidenav-hamburger">
        <input type="checkbox" id="menu-btn-check"/>
        <label htmlFor="menu-btn-check" className="Sidenav-hamburger__button"><span></span></label>
        <div id="Sidenav-container-sp" className="Sidenav-container-sp" onClick={ handleClick }>
          <a href='#top'>
            <div className="Sidenav-container-sp__text">Top</div>
          </a>
          <a href='#message'>
            <div className="Sidenav-container-sp__text">Message</div>
          </a>
          <a href='#tech'>
            <div className="Sidenav-container-sp__text">TechSkills</div>
          </a>
          <a href='#achievements'>
            <div className="Sidenav-container-sp__text">Achievements</div>
          </a>
          <a href='#info'>
            <div className="Sidenav-container-sp__text">Info</div>
          </a>
        </div>
      </div>
      <div className="Sidenav-container">
        <a href='#top'>
          <div className="Sidenav-container__text">Top</div>
        </a>
        <a href='#message'>
          <div className="Sidenav-container__text">Message</div>
        </a>
        <a href='#tech'>
          <div className="Sidenav-container__text">TechSkills</div>
        </a>
        <a href='#achievements'>
          <div className="Sidenav-container__text">Achievements</div>
        </a>
        <a href='#info'>
          <div className="Sidenav-container__text">Info</div>
        </a>
      </div>
    </div>
  );
}

export default Sidenav
