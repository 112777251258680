import React from 'react';
import '../stylesheets/Footer.scss';

function Footer() {
  return (
    <div id="footer" className="Footer">
      ©2021 sonic create
    </div>
  );
}

export default Footer;
